.carousel {
    height: 400px !important;
    max-height: 400px !important;
}

nav .brand-logo.center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    img { display: block; }
}

.nav-content.pinned {
    width: 100%;
    z-index: 999;
}

.content {
    background: url(/assets/images/background.png) center center no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

#map {
    width: 100%;
    height: 400px;
    margin-bottom: 40px;
}

.card .card-image {
    height: 230px;
    overflow: hidden;
}
.card .card-content { min-height: 270px; }

footer a:hover { color: #0d47a1 !important; }

@media only screen and (min-width: 601px) {
    .navbar-fixed {
        height: 100px;
    }
    nav {
        height: 100px;
        line-height: 100px;
    }
    nav.nav-extended .nav-wrapper {
        min-height: 130px;
    }
}
